import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';

import { apiService } from '../../services/api-service';

export function useBrandPublic(brandId: Nullable<string>) {
  return useSWRImmutable(
    brandId ? [brandId, 'brands'] : null,
    async ([brandId]) => {
      const resp = await apiService.brand.getPublic(brandId);
      return resp.data.brand;
    }
  );
}

export function useBrandsCount() {
  return useSWR('/brands/count', async () => {
    const resp = await apiService.brand.count();
    return resp.data.count;
  });
}
